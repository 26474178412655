/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './TimeSelection.css';

interface TimeSelectionProps {
    value: Date;
    onChange: (time: Date) => void;
}

function formatDateNumber(input: number): string {
  const prefillZeros = 2 - input.toString().length;

  let prefill = '';

  for (let i = 0; i < prefillZeros; i += 1) {
    prefill += '0';
  }

  return `${prefill}${input}`;
}

export default function TimeSelection(props: TimeSelectionProps) {
  const { onChange, value } = props;

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  function setTime(hour: number, minute: number) {
    const date = new Date();
    date.setTime(value.getTime());
    date.setHours(hour);
    date.setMinutes(minute);

    onChange(date);
  }

  function printSelections() {
    const selections = [];

    for (let i = 0; i < 24; i += 1) {
      const hour = formatDateNumber(i);

      for (let j = 0; j < 60; j += 15) {
        const minute = formatDateNumber(j);

        selections.push(
          <div
            role="menuitem"
            className="time-select-dropdown-item"
            onClick={() => {
              setTime(i, j);
              setShowDropdown(false);
            }}
          >
            {hour}
            :
            {minute}
          </div>,
        );
      }
    }

    return selections;
  }

  return (
    <Row>
      <Col>
        <div className="time-select">
          <div role="dialog" className="time-select-preview" onClick={() => { setShowDropdown(!showDropdown); }}>
            {formatDateNumber(value.getHours())}
            :
            {formatDateNumber(value.getMinutes())}
          </div>
          <div className="time-select-dropdown-container">
            <div className={showDropdown ? 'time-select-dropdown active' : 'time-select-dropdown'}>
              {printSelections()}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
