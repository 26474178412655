export default {
  translation: {

    fish: {
      title: 'Kala',
      perch: 'Ahven',
      pikeperch: 'Kuha',
      'rainbow-trout': 'Kirjolohi',
      trout: 'Taimen',
      'lake-salmon': 'Järvilohi',
      pike: 'Hauki',
      roach: 'Särki',
      bream: 'Lahna',
      whitefish: 'Siika',
      ide: 'Säyne',
    },
    gear: {
      title: 'Väline',
      rod: 'Onki',
      net: 'Verkko',
      'fish-trap': 'Katiska',
      jig: 'Jigi',
      'spinning-rod': 'Virveli',
    },
    weather: {
      title: 'Säätiedot',
      temperature: 'Lämpötila (C)',
      pressure: 'Ilmanpaine (hPa)',
      windspeed: 'Tuulennopeus (m/s)',
      winddirection: 'Tuulensuunta',
    },
    basicInformation: 'Perustiedot',
    catchTime: 'Pyyntiaika',
    fisher: 'Kalastaja',
    place: 'Paikka',
    attributes: 'Lisätiedot',
    addAttribute: 'Uusi lisätieto',
    weight: 'Paino',
    length: 'Pituus',
    save: 'Tallenna',
    back: 'Palaa',
    attributeName: 'Lisätiedon nimi',
    attributeValue: 'Lisätiedon arvo',
    draughtListHeader: 'Otsikko',
    createNewDraughtButton: 'Lisää uusi tietue',
    placeButton: 'Lisää uusi paikka',
    placeSaveDescription: 'Anna paikalle kuvaava nimi',
  },

};
