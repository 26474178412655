import { useState } from 'react';
import {
  Button, FormControl, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner,
} from 'react-bootstrap';
import { Selection } from '../../context/DraughtContext';

interface SelectionModalProps {
    showModal: boolean;
    handleClose: () => void;
    selectedType: string;
    saving: boolean;
    saveSelection: (selection: Selection) => void;
}

export default function SelectionModal(props: SelectionModalProps) {
  const {
    showModal, handleClose, selectedType, saving, saveSelection,
  } = props;

  const [value, setValue] = useState<string>('');

  return (
    <Modal show={showModal} onHide={handleClose}>
      <ModalHeader closeButton>
        Create a new selection for
        {' '}
        {selectedType}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <FormControl value={value} onChange={(event: any) => { setValue(event.target.value); }} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        { !saving ? <Button variant="primary" onClick={() => { saveSelection({ name: value, type: selectedType }); }}>Save</Button> : <Spinner animation="border" /> }
        <Button variant="secondary" onClick={() => { handleClose(); }}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
