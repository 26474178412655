import { DataGrid } from '@mui/x-data-grid';
import { Selection } from '../../context/DraughtContext';

interface PlaceListingProps {
    places: Selection[];
}

export default function PlaceListing(props: PlaceListingProps) {
  const { places } = props;

  const columns = [{
    field: 'name',
    headerName: 'Name',
  }, {
    field: 'latitude',
    headerName: 'Latitude',
  }, {
    field: 'longitude',
    headerName: 'Longitude',
  }];

  const rows = places.map((place, index) => {
    const latitude = (place.additionalAttributes || []).find((attribute) => attribute.name === 'latitude')?.value;
    const longitude = (place.additionalAttributes || []).find((attribute) => attribute.name === 'longitude')?.value;

    return {
      id: index,
      name: place.name,
      latitude,
      longitude,
    };
  });

  return (
    <div style={{ height: 300, width: '100%' }}>
      <DataGrid columns={columns} rows={rows} />
    </div>
  );
}
