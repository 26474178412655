import { Slider } from '@mui/material';
import { Button } from 'react-bootstrap';

interface WeightSelectionProps {
    value: number;
    onChange: (value: number) => void;
}

export default function WeightSelection(props: WeightSelectionProps) {
  const { value, onChange } = props;

  const kg = Math.floor(value / 1000);
  const g = value - (kg * 1000);

  function handleKg(total: number) {
    onChange((total * 1000) + g);
  }

  function handleG(total: number) {
    onChange((kg * 1000) + total);
  }

  return (
    <>
      <Slider
        min={0}
        max={15}
        step={1}
        value={kg}
        onChange={(_event, changedValue) => { handleKg(changedValue as number); }}
      />
      <Slider
        min={0}
        max={950}
        step={50}
        value={g}
        onChange={(_event, changedValue) => { handleG(changedValue as number); }}
      />
      <Button disabled variant="info">
        {value}
        {' '}
        g
      </Button>
    </>
  );
}
