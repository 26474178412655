export default {
  form: {
    fish: 'Fish',
    gear: 'Gear',
    fisher: 'Fisher',
    place: 'Place',
    attributes: 'Attributes',
    addAttribute: 'Add new attribute',
    weight: 'Weight',
    length: 'Length',
    save: 'Save',
    back: 'Go back',
    attributeName: 'Attribute name',
    attributeValue: 'Attribute value',
  },
};
