/* eslint-disable react/jsx-no-constructed-context-values */
import { API, Auth } from 'aws-amplify';
import {
  ReactNode, useEffect, useState,
} from 'react';
import DraughtContext, { Draught, DraughtBody, Selection } from '../context/DraughtContext';

interface DraughtProviderProps {
    children: ReactNode;
}

export default function DraughtProvider(props: DraughtProviderProps) {
  const { children } = props;

  const [draughts, setDraughts] = useState<Draught[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [selections, setSelections] = useState<Selection[]>([]);
  const [savingSelection, setSavingSelection] = useState<boolean>(false);
  const [showSelectionModal, setShowSelectionModal] = useState<boolean>(false);
  const [selectedDraught, setSelectedDraught] = useState<Draught>();

  async function getDraughts() {
    const authenticationSession = await Auth.currentSession();

    const response = await API.get('DraughtAPI', '/draughts', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authenticationSession.getAccessToken().getJwtToken(),
      },
    });

    setLoading(false);
    setDraughts(response);
  }

  async function getSelections() {
    const authenticationSession = await Auth.currentSession();

    const response = await API.get('SelectionAPI', '/selections', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authenticationSession.getAccessToken().getJwtToken(),
      },
    });

    setSelections(response);
  }

  async function getDraught(id: string) {
    if (selectedDraught?.draughtId !== id) {
      setLoading(true);

      const authenticationSession = await Auth.currentSession();

      const response = await API.get('DraughtAPI', `/draughts/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authenticationSession.getAccessToken().getJwtToken(),
        },
      });

      setLoading(false);
      setSelectedDraught(response);
    }
  }

  useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);
      getDraughts();
      getSelections();
    }
  });

  async function saveDraught(draught: DraughtBody) {
    const authenticationSession = await Auth.currentSession();

    setSaving(true);

    try {
      const response = await API.post('DraughtAPI', '/draughts', {
        body: draught,
        headers: {
          'Content-Type': 'application/json',
          Authorization: authenticationSession.getAccessToken().getJwtToken(),
        },
      });

      setDraughts([...draughts, { ...draught, draughtId: response.draughtId }]);
      setSaving(false);
    } catch (error) {
      setSaving(false);
    }
  }

  function showModal() {
    setShowSelectionModal(true);
  }

  function hideModal() {
    setShowSelectionModal(false);
  }

  async function saveSelection(selection: Selection) {
    const authenticationSession = await Auth.currentSession();

    setSavingSelection(true);

    try {
      await API.post('SelectionAPI', '/selections', {
        body: selection,
        headers: {
          'Content-Type': 'application/json',
          Authorization: authenticationSession.getAccessToken().getJwtToken(),
        },
      });

      setSelections([...selections, selection]);
      setSavingSelection(false);
      setShowSelectionModal(false);
    } catch (error) {
      setSaving(false);
    }
  }

  async function deleteDraught(draughtId: string) {
    const authenticationSession = await Auth.currentSession();

    try {
      await API.del('DraughtAPI', `/draughts/${draughtId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authenticationSession.getAccessToken().getJwtToken(),
        },
      });

      setDraughts(draughts.filter((draught) => draught.draughtId !== draughtId));
    } catch (error) {
      console.error('Could not delete draught');
    }
  }

  return (
    <DraughtContext.Provider value={{
      draughts,
      getDraughts,
      saveDraught,
      deleteDraught,
      saving,
      selections,
      saveSelection,
      savingSelection,
      showSelectionModal,
      showModal,
      hideModal,
      loading,
      getDraught,
      selectedDraught,
    }}
    >
      {children}
    </DraughtContext.Provider>
  );
}
