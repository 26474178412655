import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import DraughtContext from '../context/DraughtContext';

export default function DraughtViewPage() {
  const { t } = useTranslation();

  const { id } = useParams<'id'>();

  function printWindDirection(direction: number): string {
    const breakpoints = [0, 45, 90, 135, 180, 225, 270, 315, 360];
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N'];
    const maxDiff = 22.5;

    let point = 0;

    breakpoints.forEach((breakpoint, index) => {
      const diff = Math.abs(breakpoint - direction);

      if (diff < maxDiff) {
        point = index;
      }
    });

    return `${directions[point]} (${direction})`;
  }

  return (
    <DraughtContext.Consumer>
      {({ getDraught, loading, selectedDraught }) => {
        if (!loading && (!selectedDraught || selectedDraught.draughtId !== id)) {
          getDraught(id as string);
        }
        if (loading) {
          return <Spinner animation="grow" />;
        }

        if (!loading && !selectedDraught) {
          return (
            <div>
              Draught
              {' '}
              {id}
              {' '}
              not found :/
            </div>
          );
        }

        if (selectedDraught) {
          const {
            fish, gear, weight, length, catchTime, place, additionalAttributes, weather,
          } = selectedDraught;

          return (
            <div>
              <Button variant="info"><Link to="/">Back</Link></Button>
              <dl className="dl-horizontal">
                <h3>{t('basicInformation')}</h3>
                <dt>{t('catchTime')}</dt>
                <dd><Moment format="DD.MM.YYYY hh:mm" unix>{catchTime / 1000}</Moment></dd>
                <dt>{t('fish.title')}</dt>
                <dd>{t(`fish.${fish}`)}</dd>
                <dt>{t('gear.title')}</dt>
                <dd>{t(`gear.${gear}`)}</dd>
                <dt>{t('weight')}</dt>
                <dd>{weight}</dd>
                <dt>{t('length')}</dt>
                <dd>{length}</dd>
                <dt>{t('place')}</dt>
                <dd>{place?.name}</dd>
              </dl>
              <Map
                latitude={place?.latitude}
                longitude={place?.longitude}
                zoom={13}
                mapboxAccessToken="pk.eyJ1IjoiYW56YWJhbnphIiwiYSI6ImNrb28ybmhwcDA3ZXoyb3Bpb3RvNjkxbjEifQ.CkRHDqBgK92tZCtW3S9KIA"
                style={{ width: '100%', height: 400 }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
              >
                <Marker latitude={place?.latitude} longitude={place?.longitude} />
              </Map>
              {additionalAttributes && additionalAttributes.length > 0
                ? (
                  <dl className="dl-horizontal">
                    <h3>{t('attributes')}</h3>

                    {(additionalAttributes || []).map((attribute) => (
                      <>
                        <dt>{attribute.name}</dt>
                        <dd>{attribute.value}</dd>
                      </>
                    ))}
                  </dl>
                ) : null}
              {weather ? (
                <dl className="dl-horizontal">
                  <h3>{t('weather.title')}</h3>
                  <dt>{t('weather.temperature')}</dt>
                  <dd>
                    {weather?.temperature}
                    {' '}
                    °C
                  </dd>
                  <dt>{t('weather.pressure')}</dt>
                  <dd>
                    {weather?.pressure}
                    {' '}
                    hPa
                  </dd>
                  <dt>{t('weather.windspeed')}</dt>
                  <dd>
                    {weather?.windspeed}
                    {' '}
                    m/s
                  </dd>
                  <dt>{t('weather.winddirection')}</dt>
                  <dd>{printWindDirection(weather?.winddirection)}</dd>
                </dl>
              ) : null}
            </div>
          );
        }

        return null;
      }}
    </DraughtContext.Consumer>
  );
}
