import Map, {
  MapLayerMouseEvent, Marker,
} from 'react-map-gl';
import { Attribute } from '../../containers/DraughtCreationPage';
import { Coordinates } from '../../containers/PlacePage';
import { Selection } from '../../context/DraughtContext';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'mapbox-gl/dist/mapbox-gl.css';

import './InteractiveMap.css';

interface InteractiveMapProps {
    location?: Coordinates;
    places?: Selection[];
    onMapClick: (location: Coordinates) => void;
}

function findAttribute(attributes: Attribute[], type: string) {
  return attributes.find((attribute) => attribute.name === type)?.value;
}

export default function InteractiveMap(props: InteractiveMapProps) {
  const { location, onMapClick, places } = props;

  const markers = [];

  if (location) {
    markers.push(location);
  }

  const selectedPlaces = (places || []).map((place: Selection) => {
    const attributes = place.additionalAttributes || [];

    const latitude = findAttribute(attributes, 'latitude') as number;
    const longitude = findAttribute(attributes, 'longitude') as number;

    return {
      latitude,
      longitude,
    };
  });

  const viewState = {
    latitude: 62,
    longitude: 28,
    zoom: 11,
  };

  return (
    <div className="interactive-place-map">
      <Map
        initialViewState={viewState}
        mapboxAccessToken="pk.eyJ1IjoiYW56YWJhbnphIiwiYSI6ImNrb28ybmhwcDA3ZXoyb3Bpb3RvNjkxbjEifQ.CkRHDqBgK92tZCtW3S9KIA"
        style={{ width: '100%', height: 400 }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        onClick={(event: MapLayerMouseEvent) => {
          const selectedLocation = {
            latitude: event.lngLat.lat,
            longitude: event.lngLat.lng,
          };
          onMapClick(selectedLocation);
        }}
      >
        {selectedPlaces.map((place: Coordinates) => <Marker style={{ fill: 'rgb(0, 255, 0)' }} longitude={place.longitude} latitude={place.latitude} />)}
        {location ? <Marker longitude={location.longitude} latitude={location.latitude} /> : null}
      </Map>
    </div>
  );
}

InteractiveMap.defaultProps = {
  location: null,
  places: null,
};
