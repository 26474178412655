import { useState } from 'react';
import {
  Button, Form, FormControl, FormGroup, FormLabel,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InteractiveMap from '../components/map/InteractiveMap';
import PlaceListing from '../components/places/PlaceListing';
import DraughtContext, { Selection } from '../context/DraughtContext';

export interface Coordinates {
    latitude: number;
    longitude: number;
}

function filterSelections(selections: Selection[], type: string) {
  return selections.filter((selection) => selection.type === type);
}

export default function PlacePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [location, setLocation] = useState<Coordinates>();

  return (
    <DraughtContext.Consumer>
      {({
        saveSelection, selections,
      }) => (
        <div>
          <Form onSubmit={(event) => {
            event.preventDefault();
            if (location) {
              saveSelection({
                type: 'place',
                name,
                additionalAttributes: [{
                  name: 'latitude',
                  value: location?.latitude,
                }, {
                  name: 'longitude',
                  value: location?.longitude,
                }],
              });
            }
          }}
          >
            <InteractiveMap places={filterSelections(selections, 'place')} location={location} onMapClick={(selectedLocation: Coordinates) => { setLocation(selectedLocation); }} />
            <FormGroup>
              <FormLabel>{t('placeSaveDescription')}</FormLabel>
              <FormControl value={name} onChange={(event) => { setName(event.target.value); }} />
            </FormGroup>
            <Button type="submit">
              {t('save')}
            </Button>
            <Button variant="secondary" onClick={() => { navigate('/'); }}>
              {t('back')}
            </Button>
          </Form>

          <PlaceListing places={filterSelections(selections, 'place')} />
        </div>
      )}
    </DraughtContext.Consumer>

  );
}
