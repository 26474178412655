/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import {
  Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row,
} from 'react-bootstrap';
import { Navigate, useLocation } from 'react-router-dom';
import AuthenticationContext from '../context/AuthenticationContext';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const location = useLocation();

  return (
    <AuthenticationContext.Consumer>
      {({ login, user }) => (
        <>
          { !user ? (
            <Container>
              <Row>
                <Col xs={12} md={8}>
                  <Form onSubmit={(event) => {
                    event.preventDefault();
                    login(email, password);
                    return false;
                  }}
                  >
                    <FormGroup>
                      <FormLabel>
                        Email
                      </FormLabel>
                      <FormControl value={email} placeholder="email@example.com" onChange={(event) => { setEmail(event.target.value); }} />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>
                        Password
                      </FormLabel>
                      <FormControl value={password} type="password" onChange={(event) => { setPassword(event.target.value); }} />
                    </FormGroup>
                    <Button type="submit">Login</Button>
                  </Form>
                </Col>
              </Row>
            </Container>
          ) : <Navigate to="/" state={{ from: location }} />}
        </>
      )}
    </AuthenticationContext.Consumer>
  );
}
