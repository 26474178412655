import { DataGrid, GridValueFormatterParams } from '@mui/x-data-grid';
// import moment from 'moment';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import DraughtContext, { Draught } from '../context/DraughtContext';

export default function DraughtPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function mapDraughts(draughts: Draught[]) {
    return draughts.map((draught) => ({
      ...draught,
      id: draught.draughtId,
      placeName: draught.place?.name,
      fish: t(`fish.${draught.fish}`),
      gear: t(`gear.${draught.gear}`),
    }));
  }

  return (

    <>
      <header>
        <h1>{t('draughtListHeader')}</h1>
      </header>
      <DraughtContext.Consumer>
        {({ draughts, loading }) => {
          if (loading) {
            return <Spinner animation="grow" />;
          }

          return (
            <>
              <Link to="/create">
                <Button>
                  {t('createNewDraughtButton')}
                </Button>
              </Link>
              <Link to="/places">
                <Button>
                  {t('placeButton')}
                </Button>
              </Link>
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'catchTime', sort: 'desc' }],
                  },
                }}
                onRowClick={(event) => {
                  navigate(`/draughts/${event.id}`);
                }}
                style={{
                  height: '800px',
                }}
                columns={[{
                  field: 'catchTime',
                  headerName: t('catchTime'),
                  sortingOrder: ['desc', 'asc'],
                  width: 400,
                  type: 'date',
                  valueGetter: (entry: any) => new Date(entry.value),
                  valueFormatter: (params: GridValueFormatterParams<Date>) => moment(params.value).format('DD.MM.YYYY HH:mm:ss'),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                // eslint-disable-next-line max-len
                // eslint-disable-next-line react/no-unstable-nested-components
                }, {
                  field: 'gear',
                  headerName: t('gear.title'),
                }, {
                  field: 'fish',
                  headerName: t('fish.title'),
                }, {
                  field: 'weight',
                  headerName: t('weight'),
                  sortingOrder: ['desc', 'asc'],
                }, {
                  field: 'fisher',
                  headerName: t('fisher'),
                }, {
                  field: 'length',
                  headerName: t('length'),
                  sortingOrder: ['desc', 'asc'],
                }, {
                  field: 'placeName',
                  headerName: t('place'),
                }]}
                rows={mapDraughts(draughts)}
              />
            </>
          );
        }}
      </DraughtContext.Consumer>
    </>
  );
}
