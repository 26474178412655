import { Slider } from '@mui/material';
import { useState } from 'react';
import {
  Button, Col, Form, FormGroup, Row, Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AttributeListing from '../components/draughts/AttributeListing';
import TitleBar from '../components/draughts/TitleBar';
import ButtonSelection from '../components/form/ButtonSelection';
import DateSelection from '../components/form/DateSelection';
import SelectionModal from '../components/form/SelectionModal';
import TimeSelection from '../components/form/TimeSelection';
import WeightSelection from '../components/form/WeightSelection';
import ReadOnlyMap from '../components/map/ReadOnlyMap';
import DraughtContext, { Place, Selection } from '../context/DraughtContext';
import { Coordinates } from './PlacePage';

export interface Attribute {
    name: string;
    value: string | number;
}

export default function DraughtCreationPage() {
  const { t } = useTranslation();

  const [fish, setFish] = useState<string>('');
  const [gear, setGear] = useState<string>('');
  const [fisher, setFisher] = useState<string>('');
  const [weight, setWeight] = useState<number>(0);
  const [length, setLength] = useState<number>(0);
  const [attributes, setAttributes] = useState<any[]>([]);
  const [date, setDate] = useState<Date>(new Date());
  const [modalType, setModalType] = useState<string>('');
  const [place, setPlace] = useState<Selection | undefined >(undefined);

  const navigate = useNavigate();

  function addNewAttribute() {
    setAttributes([...attributes, { name: '', value: '' }]);
  }

  function handleChange(value: string, field: string, index: number) {
    const copyAttributes = [...attributes];

    copyAttributes[index][field] = value;

    setAttributes(copyAttributes);
  }

  function handleDelete(index: number) {
    const copyAttributes = [...attributes];

    copyAttributes.splice(index, 1);

    setAttributes(copyAttributes);
  }

  function findAttribute(needle: Attribute[], type: string) {
    return needle.find((attribute: Attribute) => attribute.name === type)?.value;
  }

  function getPlace(selection: Selection): Coordinates {
    return {
      latitude: findAttribute(selection?.additionalAttributes || [], 'latitude') as number,
      longitude: findAttribute(selection?.additionalAttributes || [], 'longitude') as number,
    };
  }

  function filterSelections(selections: Selection[], type: string) {
    return selections.filter((selection) => selection.type === type).map((selection) => ({
      key: selection.name,
      displayValue: t(`${type}.${selection.name}`, selection.name),
    }));
  }

  function findSelection(selections: Selection[], type: string, name: string) {
    return selections.find((selection) => selection.name === name && selection.type === type);
  }

  function mapPlace(selectedPlace?: Selection) {
    return {
      name: selectedPlace?.name,
      latitude: findAttribute(selectedPlace?.additionalAttributes || [], 'latitude'),
      longitude: findAttribute(selectedPlace?.additionalAttributes || [], 'longitude'),
    };
  }

  return (
    <DraughtContext.Consumer>
      {({
        saveDraught,
        selections,
        saving,
        saveSelection,
        savingSelection,
        showSelectionModal,
        showModal,
        hideModal,
      }) => (
        <>
          <Form onSubmit={(event) => {
            event?.preventDefault();
            if (!saving) {
              saveDraught({
                fish,
                fisher,
                gear,
                catchTime: date.getTime(),
                weight,
                length,
                place: mapPlace(place) as Place,
                additionalAttributes: attributes,
              });
            }
          }}
          >
            <FormGroup className="mb-3">
              <TitleBar title={t('fish.title')} />
              <ButtonSelection values={filterSelections(selections, 'fish')} onSelect={(value: string) => { setFish(value); }} />
            </FormGroup>
            <FormGroup className="mb-3">
              <TitleBar
                title={t('gear.title')}
                onAdd={() => {
                  showModal();
                  setModalType('gear');
                }}
              />
              <ButtonSelection values={filterSelections(selections, 'gear')} onSelect={(value: string) => { setGear(value); }} />
            </FormGroup>
            <FormGroup className="mb-3">
              <TitleBar
                title={t('fisher')}
                onAdd={() => {
                  showModal();
                  setModalType('fisher');
                }}
              />
              <ButtonSelection values={filterSelections(selections, 'fisher')} onSelect={(value: string) => { setFisher(value); }} />
            </FormGroup>
            <FormGroup className="mb-3">
              <TitleBar title={t('place')} />
              <ButtonSelection
                values={filterSelections(selections, 'place')}
                onSelect={(value: string) => {
                  const selectedPlace = findSelection(selections, 'place', value);
                  setPlace(selectedPlace);
                }}
              />
              {place
                ? <ReadOnlyMap place={getPlace(place)} />
                : null}
            </FormGroup>
            <FormGroup className="mb-3">
              <TitleBar title={t('weight')} />
              <WeightSelection value={weight} onChange={(value) => { setWeight(value); }} />
            </FormGroup>
            <FormGroup className="mb-3">
              <TitleBar title={t('length')} />
              <Slider
                value={length}
                min={5}
                step={1}
                max={100}
                onChange={(_event, value) => { setLength(value as number); }}
              />
              <Button disabled variant="info">
                {length}
                {' '}
                cm
              </Button>
            </FormGroup>
            <Row>
              <Col md={6} xs={6}>
                <DateSelection date={date} onChange={(value: Date) => { setDate(value); }} />
              </Col>
              <Col md={6} xs={6}>
                <TimeSelection value={date} onChange={(value: Date) => { setDate(value); }} />
              </Col>
            </Row>
            <FormGroup>
              <Button onClick={() => {
                addNewAttribute();
              }}
              >
                {t('addAttribute')}

              </Button>
              <AttributeListing
                attributes={attributes}
                handleChange={handleChange}
                handleDelete={handleDelete}
              />
            </FormGroup>
            <FormGroup>
              { !saving ? <Button type="submit">{t('save')}</Button> : <Spinner animation="border" /> }
              <Button variant="light" onClick={() => { navigate('/'); }}>{t('back')}</Button>
            </FormGroup>
          </Form>
          <SelectionModal
            showModal={showSelectionModal}
            saving={savingSelection}
            selectedType={modalType}
            saveSelection={saveSelection}
            handleClose={() => { hideModal(); }}
          />
        </>
      )}
    </DraughtContext.Consumer>

  );
}
