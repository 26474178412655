/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import {
  Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import Moment from 'react-moment';

interface DateSelectionProps {
    date: Date;
    onChange: (date: Date) => void;
}

export default function DateSelection(props: DateSelectionProps) {
  const { date, onChange } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  function handleChange(selectedDate: Date) {
    onChange(selectedDate);
    setShowModal(false);
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <div>
      <span role="button" onClick={() => { setShowModal(true); }}>
        <Moment format="DD.MM.YYYY" date={date} />
      </span>

      <Modal show={showModal}>
        <ModalHeader>
          <h3>Select date</h3>
        </ModalHeader>
        <ModalBody>
          <DatePicker value={date} onChange={handleChange} />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button onClick={closeModal}>Save</Button>
            <Button onClick={closeModal}>Close</Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </div>
  );
}
