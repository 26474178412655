/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import AuthenticationContext from '../context/AuthenticationContext';

interface AuthenticationProviderProps {
    children: React.ReactNode;
}

export default function AuthenticationProvider(props: AuthenticationProviderProps) {
  const { children } = props;

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(undefined);
  const [initialLoad, setInitialLoad] = useState(true);
  const [userError] = useState(undefined);

  async function getCurrentUser() {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();

      setUser(authenticatedUser);
      setLoading(false);
    } catch (error) {
      console.error('Could not find current authenticated user', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!user && initialLoad) {
      setInitialLoad(false);
      getCurrentUser();
    }
  });

  async function login(email: string, password: string) {
    try {
      console.log('LOGGING IN');
      const authUser = await Auth.signIn(email, password);

      setUser(authUser);
    } catch (error) {
      console.log('Could not authenticate user');
    }
  }

  async function logout() {
    try {
      await Auth.signOut();
      setUser(undefined);
    } catch (error) {
      console.error('Could not sign out');
    }
  }

  return (
    <AuthenticationContext.Provider value={{
      user,
      loading,
      login,
      userError,
      logout,
    }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
