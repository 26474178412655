import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Routes, Route } from 'react-router-dom';
import DraughtProvider from '../provider/DraughtProvider';
import DraughtCreationPage from './DraughtCreationPage';
import DraughtPage from './DraughtPage';
import DraughtViewPage from './DraughtViewPage';
import PlacePage from './PlacePage';

export default function DashboardPage(): ReactElement {
  return (
    <Container>
      <Row>
        <Col>
          <DraughtProvider>
            <Routes>
              <Route path="/" element={<DraughtPage />} />
              <Route path="/create" element={<DraughtCreationPage />} />
              <Route path="/draughts/:id" element={<DraughtViewPage />} />
              <Route path="/places" element={<PlacePage />} />
            </Routes>
          </DraughtProvider>
        </Col>
      </Row>
    </Container>
  );
}
