import { Dropdown } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
    onLogout: () => void;
}

export default function Header(props: HeaderProps) {
  const { onLogout } = props;

  const { t } = useTranslation();

  return (
    <header style={{ paddingRight: '36px' }}>
      <Dropdown className="float-right" style={{ textAlign: 'right' }}>
        <DropdownToggle>
          {t('settings')}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => onLogout()}>{t('logout')}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </header>
  );
}
