import { ReactElement } from 'react';
import {
  RouteProps, useLocation, Navigate,
} from 'react-router-dom';
import AuthenticationContext from '../../context/AuthenticationContext';

export default function ProtectedRoute(props: RouteProps): ReactElement {
  const location = useLocation();

  const { children } = props;

  return (
    <AuthenticationContext.Consumer>
      {({ user }) => (
        user ? children : (<Navigate to="/login" state={{ from: location }} />)
      )}
    </AuthenticationContext.Consumer>
  );
}
