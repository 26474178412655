import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/common/Header';
import ProtectedRoute from './components/routes/ProtectedRoute';
import DashboardPage from './containers/DashboardPage';
import LoginPage from './containers/LoginPage';
import AuthenticationContext from './context/AuthenticationContext';

export default function DraughtApplication() {
  return (
    <AuthenticationContext.Consumer>
      {({
        logout,
      }) => (
        <div>
          <Header onLogout={logout} />
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="*">
                <Route
                  path="*"
                  element={(
                    <ProtectedRoute>
                      <DashboardPage />
                    </ProtectedRoute>
                  )}
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      )}

    </AuthenticationContext.Consumer>
  );
}
