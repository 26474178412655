/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { FaPlus } from 'react-icons/fa';
import './TitleBar.css';

interface TitleBarProps {
    title: string;
    onAdd?: () => void;
}

export default function TitleBar(props: TitleBarProps) {
  const { title, onAdd } = props;

  return (
    <div className="title-bar">
      {title}
      {onAdd ? <span role="button" className="add-icon" onClick={onAdd}><FaPlus /></span> : null }
    </div>
  );
}

TitleBar.defaultProps = {
  onAdd: null,
};
