import { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import './ButtonSelection.css';

export interface ButtonValues {
    key: string;
    displayValue: string;
}

interface ButtonSelectionProps {
    onSelect: (value: string) => void;
    values: ButtonValues[];
}

export default function ButtonSelection(props: ButtonSelectionProps) {
  const { onSelect, values } = props;

  const [selectedValue, setSelectedValue] = useState<string>('');

  function selectButton(key: string) {
    setSelectedValue(key);
    onSelect(key);
  }

  function getCorrectVariant(key: string) {
    const selected = key === selectedValue;

    return selected ? 'info' : 'primary';
  }

  return (
    <ButtonGroup className="flex-group">
      {values.sort((a, b) => (a.displayValue < b.displayValue ? -1 : 1)).map((value) => (
        <Button
          variant={getCorrectVariant(value.key)}
          onClick={() => {
            selectButton(value.key);
          }}
        >
          {value.displayValue}
        </Button>
      ))}
    </ButtonGroup>
  );
}
