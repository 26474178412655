/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Map, { Marker } from 'react-map-gl';
// eslint-disable-next-line import/no-extraneous-dependencies
import mapboxgl from 'mapbox-gl';
import { Coordinates } from '../../containers/PlacePage';

interface ReadOnlyProps {
    place: Coordinates;
}

// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function ReadOnlyMap(props: ReadOnlyProps) {
  const { place } = props;

  return (
    <Map
      latitude={place.latitude}
      longitude={place.longitude}
      zoom={13}
      mapboxAccessToken="pk.eyJ1IjoiYW56YWJhbnphIiwiYSI6ImNrb28ybmhwcDA3ZXoyb3Bpb3RvNjkxbjEifQ.CkRHDqBgK92tZCtW3S9KIA"
      style={{ width: '100%', height: 400 }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
    >
      <Marker latitude={place.latitude} longitude={place.longitude} />
    </Map>
  );
}
