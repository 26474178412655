import React from 'react';

interface AuthenticationStateProps {
    user: any,
    loading: boolean,
    login: (username: string, password: string) => void;
    userError?: string;
    logout: () => void;
}

const defaultState: AuthenticationStateProps = {
  user: undefined,
  loading: true,
  login: (username, password) => {
    console.log(`Log in with ${username} and ${password}`);
  },
  logout: () => {
    console.log('Log out');
  },
};

const AuthenticationContext = React.createContext(defaultState);

export default AuthenticationContext;
