import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Amplify } from 'aws-amplify';
import AuthenticationProvider from './provider/AuthenticationProvider';
import DraughtApplication from './DraughtApplication';

import './i18n';

Amplify.configure({
  Auth: {
    // (required)- Amazon Cognito Region
    region: 'eu-north-1',
    // (optional) - Amazon Cognito User Pool ID
    userPoolId: 'eu-north-1_2hwDYEDAH',

    userPoolWebClientId: '329k0cbn5m4recpml0e45n1sr7',
  },
  API: {
    endpoints: [
      {
        name: 'DraughtAPI',
        endpoint: 'https://1hfyu2vffb.execute-api.eu-north-1.amazonaws.com/prod',
      },
      {
        name: 'SelectionAPI',
        endpoint: 'https://mbqieymm8k.execute-api.eu-north-1.amazonaws.com/prod',
      },
    ],
  },
});

function App() {
  return (
    <div className="App">
      <AuthenticationProvider>
        <DraughtApplication />
      </AuthenticationProvider>
    </div>
  );
}

export default App;
