import React from 'react';
import { Attribute } from '../containers/DraughtCreationPage';
import { Coordinates } from '../containers/PlacePage';

interface Weather {
    windgust: number;
    pressure: number;
    winddirection: number;
    windspeed: number;
    temperature: number;
    time: string;
}

export interface Place extends Coordinates {
    name: string;
}

export interface DraughtBody {
    fish: string;
    fisher: string;
    gear: string;
    catchTime: number;
    weight: number;
    length?: number;
    place?: Place;
    additionalAttributes?: Attribute[];
    weather?: Weather;
}

export interface Draught extends DraughtBody {
    draughtId: string;
}

export interface Selection {
    name: string;
    type: string;
    additionalAttributes?: Attribute[];
}

interface DraughtStateProps {
    draughts: Draught[];
    getDraughts: () => void;
    getDraught:(id: string) => void;
    saveDraught: (draught: DraughtBody) => void;
    deleteDraught: (draughtId: string) => void;
    saving: boolean;
    selections: Selection[];
    saveSelection: (selection: Selection) => void;
    savingSelection: boolean;
    showSelectionModal: boolean;
    showModal: () => void;
    hideModal: () => void;
    loading: boolean;
    selectedDraught?: Draught;
}

const defaultState: DraughtStateProps = {
  draughts: [],
  getDraughts: () => {
    console.log('Get draughts');
  },
  getDraught: (id: string) => {
    console.log(`Draught got with id ${id}`);
  },
  saveDraught: (draught: DraughtBody) => {
    console.log(draught);
  },
  deleteDraught: (draughtId: string) => {
    console.log(draughtId);
  },
  saving: false,
  selections: [],
  saveSelection: (selection: Selection) => {
    console.log(selection);
  },
  savingSelection: false,
  showSelectionModal: false,
  showModal: () => {
    console.log('Show modal');
  },
  hideModal: () => {
    console.log('Hide modal');
  },
  loading: true,
};

const DraughtContext = React.createContext(defaultState);

export default DraughtContext;
