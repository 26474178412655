/* eslint-disable react/no-array-index-key */
import {
  Button, Col, FormControl, FormLabel, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Attribute } from '../../containers/DraughtCreationPage';

interface AttributeListingProps {
    attributes: Attribute[];
    handleChange: (value: string, field: string, index: number) => void;
    handleDelete: (index: number) => void;
}

export default function AttributeListing(props: AttributeListingProps) {
  const { attributes, handleChange, handleDelete } = props;

  const { t } = useTranslation();

  return (
    <>
      {attributes.map((attribute, index) => (
        <Row className="mb-3" key={index}>
          <Col>
            <FormLabel>{t('attributeName')}</FormLabel>
            <FormControl value={attribute.name} onChange={(event) => { handleChange(event.target.value, 'name', index); }} placeholder={t('attributeName')} />
          </Col>
          <Col>
            <FormLabel>{t('attributeValue')}</FormLabel>
            <FormControl value={attribute.value} onChange={(event) => { handleChange(event.target.value, 'value', index); }} placeholder={t('attributeValue')} />
          </Col>
          <Button variant="danger" onClick={() => { handleDelete(index); }}>
            X
          </Button>
        </Row>
      ))}
    </>
  );
}
